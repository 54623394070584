import { render, staticRenderFns } from "./FinalisationPage.vue?vue&type=template&id=072b57d6&scoped=true"
import script from "./FinalisationPage.vue?vue&type=script&lang=js"
export * from "./FinalisationPage.vue?vue&type=script&lang=js"
import style0 from "./FinalisationPage.vue?vue&type=style&index=0&id=072b57d6&prod&scoped=true&lang=css"
import style1 from "./FinalisationPage.vue?vue&type=style&index=1&id=072b57d6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072b57d6",
  null
  
)

export default component.exports