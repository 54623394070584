<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4
        class="font-weight-bold text-dark"
        v-b-toggle="'claims-history-panel'"
      >
        <i class="fas fa-house-damage fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Claims History</span>

        <i
          v-if="!isExpanded"
          class="fas fa-angle-double-down fa-md float-right"
          title="Click to Expand"
        ></i>
        <i
          v-if="isExpanded"
          class="fas fa-angle-double-up fa-md float-right"
          title="Click to Collapse"
        ></i>
      </h4>

      <b-collapse id="claims-history-panel" v-model="isExpanded" v-if="claims">
        <table
          class="table table-striped"
          v-if="Array.isArray(this.claims) && this.claims.length > 0"
        >
          <thead>
            <th width="5%"></th>
            <th width="20%">Insurer</th>
            <th width="15%">Reference</th>
            <th width="10%">Date of Loss</th>
            <th width="10%">Status</th>
            <th width="10%">Incurred Amount</th>
            <th width="30%">Description</th>
          </thead>
          <tbody>
            <tr v-for="(claim, index) in this.claims" v-bind:key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ claim.K_Insurer ? claim.K_Insurer.name : "" }}</td>
              <td>{{ claim.K_InsurerClaimNumber }}</td>
              <td>
                <span v-if="claim.K_DateOfLoss">{{
                  claim.K_DateOfLoss
                    | dateParse("YYYY-MM-DD")
                    | dateFormat("DD/MM/YYYY")
                }}</span>
              </td>
              <td>{{ claim.K_Status }}</td>
              <td>
                <span v-if="claim.K_TotalClaimCost">{{
                  claim.K_TotalClaimCost | currency
                }}</span>
              </td>
              <td>{{ claim.K_DescriptionOfLossDamage }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>No claims history available</div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "ClaimsHistory",

  props: {
    claims: Array,
  },

  data() {
    return {
      isExpanded: true,
    };
  },
};
</script>
