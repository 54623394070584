<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4
        class="font-weight-bold text-dark"
        v-b-toggle="'compliance-details-panel'"
      >
        <i class="fas fa-check-circle fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center"
          >Compliance Details</span
        >

        <i
          v-if="!isExpanded"
          class="fas fa-angle-double-down fa-md float-right"
          title="Click to Expand"
        ></i>
        <i
          v-if="isExpanded"
          class="fas fa-angle-double-up fa-md float-right"
          title="Click to Collapse"
        ></i>
      </h4>

      <b-collapse
        id="compliance-details-panel"
        v-model="isExpanded"
        v-if="asset"
      >
        <!-- ROW 1 -->
        <div class="form-group row mb-4 mt-4">
          <label class="col-2 text-right">ACP / Cladding on Building</label>
          <div class="col-2 font-weight-bold">
            {{ asset.cladding }}
          </div>

          <label class="col-2 text-right">Asbestos</label>
          <div class="col-2 font-weight-bold">
            {{ asset.asbestos }}
          </div>

          <label class="col-2 text-right">Known Defects</label>
          <div class="col-2 font-weight-bold">
            {{ asset.knownDefects }}
          </div>
        </div>

        <!-- ROW 2 -->
        <div class="form-group row mb-4">
          <label class="col-2 text-right">Heritage Listed</label>
          <div class="col-2 font-weight-bold">
            {{ asset.heritageListed }}
          </div>

          <label class="col-2 text-right">Outstanding WH&S Matters</label>
          <div class="col-2 font-weight-bold">
            {{ asset.outstandingWhsItems }}
          </div>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";

export default {
  name: "ComplianceDetails",
  props: {
    asset: Object,
  },

  data() {
    return {
      isExpanded: true,
    };
  },

  computed: {
    assetCrmLink: function () {
      if (this.asset) {
        return `https://crm.zoho.com.au/crm/tab/${this.asset.K_CustomModuleName}/${this.asset.id}`;
      }
      return null;
    },

    assetAddressMapLink: function () {
      let address = _.get(this.asset, "K_Address");
      if (address) {
        return `https://maps.google.com/?q=${address.replace(/ /g, "+")}`;
      }
      return null;
    },
  },
};
</script>
