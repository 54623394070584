<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4
        class="font-weight-bold text-dark"
        v-b-toggle="'strata-contact-panel'"
      >
        <i class="fas fa-user-circle fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Strata Contact</span>

        <i
          v-if="!isExpanded"
          class="fas fa-angle-double-down fa-md float-right"
          title="Click to Expand"
        ></i>
        <i
          v-if="isExpanded"
          class="fas fa-angle-double-up fa-md float-right"
          title="Click to Collapse"
        ></i>
      </h4>

      <b-collapse
        id="strata-contact-panel"
        v-model="isExpanded"
        v-if="strataCompany"
      >
        <!-- ROW 1 -->
        <div class="form-group row mb-4 mt-4">
          <label class="col-2 text-right">Strata Company</label>
          <div class="col-2 font-weight-bold">
            <a :href="strataCompanyCrmLink" target="_blank">
              {{ strataCompany.Name }}
            </a>
          </div>

          <label class="col-2 text-right">Primary Contact</label>
          <div class="col-2 font-weight-bold">
            <a :href="strataCompanyContactCrmLink" target="_blank">
              {{ strataCompanyContactName }}
            </a>
          </div>
        </div>

        <!-- ROW 2 -->
        <div class="form-group row mb-4">
          <label class="col-2 text-right">Email</label>
          <div class="col-2 font-weight-bold">
            <a
              v-if="strataCompanyEmail"
              :href="`mailto:${strataCompanyEmail}`"
              target="_blank"
            >
              {{ strataCompanyEmail }}
            </a>
          </div>

          <label class="col-2 text-right">Phone</label>
          <div class="col-2 font-weight-bold">
            {{ strataCompanyPhone }}
          </div>

          <label class="col-2 text-right">Mobile</label>
          <div class="col-2 font-weight-bold">
            {{ strataCompanyMobile }}
          </div>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";

export default {
  name: "StrataContact",
  props: {
    strataCompany: Object,
  },

  data() {
    return {
      isExpanded: true,
    };
  },

  computed: {
    strataCompanyCrmLink: function () {
      if (this.strataCompany) {
        return `https://crm.zoho.com.au/crm/tab/${this.strataCompany.K_CustomModuleName}/${this.strataCompany.id}`;
      }
      return null;
    },

    strataCompanyContactCrmLink: function () {
      let strataCompanyPrimaryContact = _.get(
        this.strataCompany,
        "K_PrimaryContact"
      );
      if (strataCompanyPrimaryContact) {
        return `https://crm.zoho.com.au/crm/tab/${strataCompanyPrimaryContact.K_CustomModuleName}/${strataCompanyPrimaryContact.id}`;
      }
      return null;
    },

    strataCompanyContactName: function () {
      let strataCompanyPrimaryContact = _.get(
        this.strataCompany,
        "K_PrimaryContact"
      );
      return strataCompanyPrimaryContact
        ? strataCompanyPrimaryContact.Name
        : null;
    },

    strataCompanyEmail: function () {
      let strataCompanyPrimaryContact = _.get(
        this.strataCompany,
        "K_PrimaryContact"
      );
      return strataCompanyPrimaryContact
        ? strataCompanyPrimaryContact.Email
        : null;
    },

    strataCompanyPhone: function () {
      let strataCompanyPrimaryContact = _.get(
        this.strataCompany,
        "K_PrimaryContact"
      );
      return strataCompanyPrimaryContact
        ? strataCompanyPrimaryContact.K_Phone
        : null;
    },

    strataCompanyMobile: function () {
      let strataCompanyPrimaryContact = _.get(
        this.strataCompany,
        "K_PrimaryContact"
      );
      return strataCompanyPrimaryContact
        ? strataCompanyPrimaryContact.K_Mobile
        : null;
    },
  },
};
</script>
